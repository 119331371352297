.text-primary {
    color: var(--primary) !important;
  }

  * {
    box-sizing: border-radius;
    font-family: "poppins" !important;
  }

  body {
    font-size: 13px;
    font-family: "poppins" !important;
    min-height: 400px;
     /* Added as bugfix */
    overflow-x: hidden;
      overflow-y: visible;
  }

  :root {
    --background-color: #eeeef5;
    --primary: #065697;
    --primary-hover: #065697;
  }

  canvas {
    display: block;
    /* vertical-align: bottom; */
  }

  .confetti-div {
    overflow-y: auto;
    min-height: 575px;
 }
  /* ---- tsparticles container ---- */
  #tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
  }