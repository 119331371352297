.tag{
    width:25px;
    position: absolute;
    margin-left: -10px;
}
* {
    box-sizing: border-radius;
    font-family: "poppins" !important;
}

body {
    font-size: 13px;
    font-family: "poppins" !important;
    /*min-height: 400px;
     /* Added as bugfix */
    overflow-x: hidden;
    overflow-y: visible;
    background: #FFFFFF !important;
}

.add-on:hover {
    transform: scale(1.2);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: ease-out 0.2s;
}

:root {
    --background-color: #fff;
    --primary: #065697;
    --primary-hover: #065697;
}

.shadow-add {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.hrx {
    opacity: 0.1;
    margin-bottom: 9px;
    margin-top: 9px;
}

.card-chip:hover {
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .05) !important;
    transition: ease-out 0.1s;
}

.card-chip {
    cursor: pointer;
    border-width: 0.3px !important;
    border: 1px #E1DFDF solid !important;
    border-radius: 15px !important;
    /* overflow: hidden; */
    /* margin-left: 1.5rem; */
}
.brdf{
    border-radius: 15px 15px 0px 0px;
}

.bd {
    border: solid red 1px !important;
}

a {
    cursor: pointer;
}

a:hover {
    color: inherit;
}

.bg-color-1 {
    background: #F5f5f5;
}

.pointer {
    cursor: pointer;
}

img {
    object-fit: cover;
}

.btn-primary {
    background-color: var(--primary);
    font-size: 14px;
    border-color: var(--primary);
    color: #fff;
    outline: none !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: transparent;
    border-color: var(--primary-hover);
    color: #065697;
    outline: 0px !important;
}

.btn-primary-outline {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
    outline: 0px !important;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline:active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #ffffff;
    outline: 0px !important;
}

.text-primary {
    color: var(--primary) !important;
}

.oppstyle {
    text-decoration: none;
    color: inherit;
}
.oppstyle:hover {
    text-decoration: underline;
    color: inherit;
}
/* a:hover .m-0.fs-6{
  text-decoration: underline;
} */

.iconify {
    width: 20px;
}
select.ListView{
    border: 0;
    background-color: #F5f5f5;
}

.terminate-button{
    background-color: white; 
    color: red;
    border: none;
    font-size: small;
    /* float: right; */
}

canvas {
    display: block;
    /*vertical-align: bottom; */
  }

  /* ---- tsparticles container ---- */
#tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .confetti-div {
    overflow-y: auto;
    /* min-height: 575px; */
 }

 /* .terminate-div{
  width: 100%;
 } */

 .create-btn a{
  color: white;
 }

 :disabled{
 /* background: #9EBDD5 !important; */
 border: none;
}
.opp-type-select {
    height: 30px;
    text-align: center;
  }
.search-bn{
    height: 40px !important;
}
* {
    box-sizing: border-radius;
    font-family: "poppins" !important;
  }

  .l-line {
    margin-left: 20px;
    transform: rotate(-24deg);
  }

  .r-line {
    margin-right: 20px;
    transform: rotate(24deg);
  }

  body {
    font-size: 13px;
    font-family: "poppins" !important;
    min-height: 400px;
    /* Added as bugfix */
    overflow-x: hidden;
    overflow-y: visible;
  }

  :root {
    --background-color: #fff;
    --primary: #065697;
    --primary-hover: #065697;
  }

  .bd {
    border: solid red 1px !important;
  }

  a {
    cursor: pointer;
  }

  a:hover {
    color: inherit;
  }

  .bg-color-1 {
    background: #f9f9f9;
  }

  .pointer {
    cursor: pointer;
  }

  img {
    object-fit: cover;
  }

  .btn-primary {
    background-color: var(--primary);
    font-size: 14px;
    border-color: var(--primary);
    color: #fff;
    outline: none !important;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: transparent;
    border-color: var(--primary-hover);
    color: #065697;
    outline: 0px !important;
  }

  .btn-primary-outline {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
    outline: 0px !important;
  }

  .btn-primary-outline:hover,
  .btn-primary-outline:focus,
  .btn-primary-outline:active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #ffffff;
    outline: 0px !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .opp-type-select {
    height: 30px;
    text-align: center;
  }

  button:disabled {
    background: #9ebdd5 !important;
    border: none;
  }