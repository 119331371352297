@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @import url('http://fonts.cdnfonts.com/css/poppins'); */

:root {
  --header-height: 4rem;
  --nav-width: 60px;
  --first-color: #065697;
  --white-color: #fff;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --primary: #065697;
  --primary-hover: #065697;
  --font: "Poppins", sans-serif;
  --color: #333333;
  --heading-color: #181059;
  --yellow: #f3b007;
}

.bd {
  border: solid 1px red !important;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: var(--font);
  /* color: var(--color); */
  /* line-height: 28px; */
  position: relative;
  /* padding: 0 1rem; */
  font-size: 14px;
  background: #f7f7f7;
  /* transition: .5s; */
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 1.3rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  /* transition: .5s; */
}

.header_toggle {
  color: var(--first-color);
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #fff;
}

.header_img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #85accc;
}

.header_img img {
  width: 35px;
}

.l-navbar {
  position: fixed;
  top: 0;
  /* width: calc(var(--nav-width) + 156px); */
  height: 100vh;
  background-color: var(--white-color);
  padding: 2rem 0rem 0 0;
  transition: 0.5s;
  overflow: auto;
  padding-bottom: var(--header-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_link {
  position: relative;
  color: var(--first-color);
  color: #444;
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--first-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.l-navbar.show {
  left: 0;
}

.nav_link.active {
  color: var(--first-color);
  background-color: #e1e8f1;
}

.nav_link.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  /* border-radius: 0px 10px 10px 0px; */
  background-color: var(--first-color);
}

.height-100 {
  height: 100vh;
}

.main {
  overflow: hidden;
  padding-top: 2rem;
  transition: 0.5s;
}

.card-g:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  transition: 0.5s;
  border: 0px !important;
}

.op-10 {
  opacity: 0.1 !important;
}

.bd-l {
  border-left-width: 15px !important;
}

.bd-l:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  transition: 0.5s;
}
.bg-body {
  background: #f7f7f7 !important;
}

@media screen and (min-width: 992px) {
  .main {
    margin: calc(var(--header-height)) 0 0 0;
    margin-left: calc(var(--nav-width) + 0rem);
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .l-navbar {
    left: 0;
    padding: 2rem 0rem 0 0;
    margin-top: calc(var(--header-height) + 0rem);
    padding-bottom: var(--header-height);
    width: calc(var(--nav-width));
  }

  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px);
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 156px);
  }

  .body-pd {
    margin-left: calc(var(--nav-width) + 156px + 0rem);
  }
}

@media screen and (max-width: 991px) {
  .main {
    margin-top: calc(var(--header-height));
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .header {
    padding: 0 1rem;
  }

  .l-navbar {
    margin-top: calc(var(--header-height) + 0rem);
    left: -30%;
    width: calc(var(--nav-width));
    position: fixed;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 70vw);
    left: 0%;
  }
}

@media screen and (max-width: 991px) {
  .line-img img {
    width: 100px;
    transform: rotate(90deg);
  }
}

.bg-default {
  background: #065697 !important;
}

.padd-t-2 {
  padding-top: 2rem;
}

.fs-small {
  font-size: 8px;
}

.fs-label {
  font-size: 12px;
}

.add-on:hover {
  transform: scale(1.2);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: ease-out 0.2s;
}

.shadow-add {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.shadow-add-2 {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.hrx {
  opacity: 0.1;
}

.card-chip:hover {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  transition: ease-out 0.1s;
}

.card-chip {
  cursor: pointer;
}

.bd {
  border: solid red 1px !important;
}

a {
  cursor: pointer;
}

a:hover {
  color: inherit;
}

.bg-color-1 {
  background: #f9f9f9;
}

.pointer {
  cursor: pointer;
}

img {
  object-fit: cover;
}

.text-justify {
  text-align: justify;
}

/* ------------------------swal-------------------- */
.swal-modal {
  vertical-align: top;
}
.swal-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
.swal-title {
  margin: 0px;
  font-size: 1.25rem;
  box-shadow: none !important;
  margin-bottom: 0px;
  color: #333333;
}
.swal-text {
  background-color: #fefae3;
  padding: 17px;
  border: 1px solid #f0e1a1;
  display: block;
  margin: 22px;
  text-align: center;
  color: #61534e;
}
.swal-footer {
  text-align: center;
}
/* ------------------------swal-------------------- */

/* ------------------------buttons-------------------- */
.btn-primary,
.btnx,
.swal-button {
  background-color: var(--primary) !important;
  font-size: 12px;
  border-color: var(--primary);
  color: #fff;
  outline: none !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  transform: scale(0.95);
  color: #fff;
  transition: 0.5s;
  border-color: var(--primary-hover);
  outline: 0px !important;
}

.btn-primary-outline {
  font-size: 12px;
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
  outline: 0px !important;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline:active {
  transform: scale(0.99);
  transition: 0.5s;
  background-color: var(--primary);
  border-color: var(--primary);
  color: #ffffff;
  outline: 0px !important;
}
.btn-disabled {
  opacity: 0.3;
}
/* ------------------------buttons-------------------- */

.text-primary {
  color: var(--primary) !important;
}

/* ------------------------input-------------------- */
.input-bn {
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px !important;
  border: 1.5px solid #8692a6 !important;
  height: 55px !important;
}

/* Chrome, Safari, Edge, Opera */
.h-bn::-webkit-outer-spin-button,
.h-bn::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.h-bn[type="number"] {
  -moz-appearance: textfield;
}

.input-bn:focus {
  border: 2px solid #065697 !important;
  box-shadow: none;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

textarea {
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px !important;
  border: 1.5px solid #8692a6 !important;
  min-height: 150px !important;
}

textarea:focus {
  border: 2px solid #065697 !important;
  box-shadow: none !important;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1 !important;

  color: #808080;
  height: 13px !important;
  padding: 2px !important;
  background: white;
  transition: 0.3s;
  overflow: visible;
  transform: scale(1) translateY(-0.6rem) translateX(0.85rem) !important;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  height: 0.5em;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.925rem !important;
}

.form-floating > .form-select {
  padding-top: 0.925rem !important;
  padding-bottom: 0.625rem;
}
/* ------------------------input-------------------- */

.testdiv1 {
  box-shadow: 4px 4px 10px #c4c4c4;
  width: 100%;
  background-color: #3240a8;
  display: flex;
  margin-top: 3%;
  color: white;
}

.Terminated_Stage {
  box-shadow: 4px 4px 10px #c4c4c4;
  width: 100%;
  background-color: #a84432;
  display: flex;
  margin-top: 3%;
  color: white;
}

.runnerIconImage {
  width: 38px;
  height: 38px;
}

.runnerBackground {
  margin: 15px;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  padding: 20px;
  background-image: linear-gradient(45deg, #f5f7f9, #abd8ff);
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dhh1 {
  color: #3240a8;
  position: absolute;
  font-size: 2.5em;
  height: 1em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: auto;
  text-align: center;
  opacity: 0;
  animation: appear 4s forwards;
  animation-iteration-count: infinite;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.boxcon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 200px;
  width: 200px;
}

.box1 {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 2px solid rgb(0, 225, 255);
  animation-name: spinx;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  text-align: center;
}

.box2 {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 2px solid rgb(0, 255, 255);
  animation-name: spiny;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  text-align: center;
}

@keyframes spinx {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}

@keyframes spiny {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

/* ------------------------auth-------------------- */
.error_message {
  color: red;
}

.reqField {
  font-size: 8px;
  color: red;
}

.missingField {
  color: red !important;
}

.auth-container {
  margin: auto, auto;
  min-width: 300px;
  border-radius: 15px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .auth-container {
    border-radius: 0px;
  }

  .auth-form {
    min-height: 85vh;
  }
}

.auth-form {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  border: #dd000d 1px solid !important;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

/* Make circles that indicate the steps of the form: */
.step {
  min-width: 60px;
  height: 10px;
  background: rgba(6, 86, 151, 0.2);
  border-radius: 100px;
  margin: 5px;
}

/* Mark the active step: */
.step.active {
  border: 1px solid #065697;
  background: rgba(6, 86, 151, 0.1);
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #065697;
}
/* ------------------------auth-------------------- */

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
@media screen and (max-width: 767px) {
  .fmb-cont {
    position: fixed;
    width: 80%;
    left: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: calc(var(--header-height) + 2rem);
    border: 1px solid #dee2e6 !important;
    border-radius: 0 20px 0 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  }
  .fmb span {
    display: block !important;
    margin-top: 5px;
    font-size: 10px;
  }
  .fmb {
    flex-direction: column;
    /* padding-left: 20px;
    padding-right: 20px; */
  }
  .fmb.active .fmb-i {
    margin: 0px !important;
  }
}
.fmb,
.fmb span {
  color: #333333;
}
.fmb.active {
  background: none !important;
}
.fmb.active span {
  font-weight: bold;
}
.fmb.active .fmb-i {
  background: #065697;
  margin-right: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}
.fmb.active .fmb-i i {
  color: #fff !important;
}
.fm-component-cont div div:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
  cursor: grab;
}
.fm-comp {
  position: relative;
}
.fm-comp:hover {
  transition: 0.1s;
  background: rgba(39, 91, 152, 0.05);
  border: 1px solid rgba(39, 94, 152, 0.18);
  border-radius: 4px;
  transform: scale(1);
  cursor: pointer;
}
.fm-widget:hover {
  display: block;
}
.fm-main {
  position: relative;
}
@media screen and (min-width: 992px) {
  .fm-navbar {
    width: calc(var(--nav-width));
    display: none;
  }
  .fm-navbar.show {
    display: block;
  }
  .fm-navbar:hover {
    width: calc(var(--nav-width));
  }
  .fm-navbar.show {
    width: calc(var(--nav-width) + 156px + 200px);
  }
  .fm-main {
    margin-left: -2rem;
  }
  .body-pd .fm-main {
    margin-left: 200px;
  }
}
@media screen and (max-width: 992px) {
  .fm-navbar {
    width: 55vw;
  }
}
@media screen and (max-width: 700px) {
  .fm-navbar {
    width: 100vw;
  }
}
.fm-toggle {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 10000;
  height: 40px;
  width: 40px;
  bottom: 0;
  top: 85vh;
  right: 10px;
  color: #fff;
  background: #065697;
}
.fmc-main {
  margin-left: 0px;
}
.btn-check:checked + .btn-primary-outline {
  background: #065697;
  color: #fff;
  border-color: #065697;
}
.btn-check:checked + .btn-primary-outline:focus {
  box-shadow: none !important;
}
.border-primary {
  border-color: #065697 !important;
}
.dot-border {
  border-style: dotted !important;
}
.fm-condi-close {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
}
.fm-condi-close:hover {
  transform: scale(1.5) !important;
  color: #a84432 !important;
  transition: 0.1s;
}
.input-title {
  border: 0px;
  background: none;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.input-title:focus {
  border: 1px solid #dee2e6 !important;
  background: none;
  box-shadow: none;
  font-size: 19px;
  color: #333;
  margin-left: 4px !important;
}
.input-title-edit {
  position: absolute;
  right: 0;
  z-index: -1;
  cursor: pointer;
}
.dt-cont {
  max-width: 320px;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  border-style: dashed !important;
  background: #eef5fd;
  font-size: 12px;
}
.form-control-color {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: 0px;
}
.form-control-color:hover,
.form-control-color:focus {
  padding: 0px;
  border: 0px;
  box-shadow: 0px;
  margin: 0px;
}

textarea {
  font-size: 14px !important;
  border-radius: 5px 0px 0px 5px !important;
  border: 0.5px solid #8692a6 !important;
  min-height: 40px !important;
  padding-top: 8px !important;
  max-height: 100px;
}

.txtp {
  font-size: 12px !important;
}

textarea {
  resize: vertical;
  overflow: hidden;
}

.txta-btn {
  height: 40px !important;
}

.search-input-btn {
  font-size: inherit;
}

.input-group-text {
  height: fit-content;
}

.close-btn {
  height: fit-content;
  border-radius: 50%;
}

.txt-container {
  width: 100% !important;
}

.back-btn {
  font-size: 25px;
}

.select-trigger:hover {
  background-color: #f7f8f8fc !important;
  box-shadow: 3px 3px #e0dddd;
  cursor: pointer;
}

.workflow-title {
  background-color: transparent;
  padding: 0px;
  outline: 0;
  font-size: 20px;
  height: 32px;
  border: 0;
}

.workflow-title:disabled {
  background-color: transparent;
  outline: 0;
  font-size: 20px;
  border: 0;
}

.workflow-description {
  background-color: transparent;
  padding: 0px;
  outline: 0;
  font-size: 12px;
  height: 32px;
  border: 0;
}

.workflow-description:disabled {
  background-color: transparent;
  outline: 0;
  font-size: 12px;
  border: 0;
}

.textbox {
  width: inherit !important;
}

/* Loader */
.cg-center {
  background: #065697;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div {
  position: absolute;
  width: 120px;
  height: 69px;
}

.div:nth-of-type(2) {
  transform: rotate(60deg);
}

.div:nth-of-type(3) {
  transform: rotate(-60deg);
}

.div div {
  width: 100%;
  height: 100%;
  position: relative;
}

.div div span {
  position: absolute;
  width: 4px;
  border-radius: 10px;
  height: 0%;
  background: #fff;
  z-index: 999999;
}

.oh1 {
  left: 0;
  animation: load1 4s ease infinite;
}

.oh2 {
  right: 0;
  animation: load2 4s ease 0.2s infinite;
}

.oh3 {
  right: 0;
  animation: load3 4s ease 0.4s infinite;
}

.oh4 {
  right: 0;
  animation: load4 4s ease 0.8s infinite;
}

.oh5 {
  left: 0;
  animation: load5 4s ease 1.4s infinite;
}

.oh6 {
  left: 0;
  animation: load6 4s ease 2s infinite;
}
/* svg{
    animation: pulse 1.5s ease-in 1s infinite;
    
} */

.ppo {
  animation: pulse 1s ease-in 1s infinite;
}

@keyframes pulse {
  25% {
    transform: scale(0.9);
    opacity: 0.9;
  }
  75% {
    transform: scale(0.95);
  }
}

@keyframes load1 {
  0% {
    bottom: 0;
    height: 0;
  }

  6.944444444% {
    bottom: 0;
    height: 100%;
  }

  50% {
    top: 0;
    height: 100%;
  }

  59.944444433% {
    top: 0;
    height: 0;
  }

  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0% {
    top: 0;
    height: 0;
  }

  6.944444444% {
    top: 0;
    height: 100%;
  }

  50% {
    bottom: 0;
    height: 100%;
  }

  59.944444433% {
    bottom: 0;
    height: 0;
  }

  /*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0% {
    top: 0;
    height: 0;
  }

  6.944444444% {
    top: 0;
    height: 100%;
  }

  50% {
    bottom: 0;
    height: 100%;
  }

  59.94444443% {
    bottom: 0;
    height: 0;
  }

  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0% {
    top: 0;
    height: 0;
  }

  6.944444444% {
    top: 0;
    height: 100%;
  }

  50% {
    bottom: 0;
    height: 100%;
  }

  59.94444443% {
    bottom: 0;
    height: 0;
  }

  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0% {
    bottom: 0;
    height: 0;
  }

  6.944444444% {
    bottom: 0;
    height: 100%;
  }

  50% {
    top: 0;
    height: 100%;
  }

  59.94444443% {
    top: 0;
    height: 0;
  }

  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0% {
    bottom: 0;
    height: 0;
  }

  6.944444444% {
    bottom: 0;
    height: 100%;
  }

  50% {
    top: 0;
    height: 100%;
  }

  59.94444443% {
    top: 0;
    height: 0;
  }

  /*   91.6666667%{top:0;height:0%;} */
}

/* Loader End */

.mloo {
  margin-left: -1px;
}

.card-g {
  cursor: pointer;
}
.txt-slate,
.txt-slate:hover,
.txt-slate:focus {
  outline: none;
  box-shadow: none;
  font-size: 12px !important;
  margin: 3px 0px;
}
.f-bd-ac {
  /* overflow-y: auto !important;
    max-height: 450px;
    padding-bottom:30px !important; */
}
.ac-btn {
  padding: 10px;
  background: transparent !important;
}
.ac-btn:focus {
  outline: none;
  box-shadow: none;
}
.gx-up {
  max-height: 400px;
  padding-bottom: 50px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.f-drop {
  border-radius: 5px;
  position: absolute;
  left: 0px;
  margin-top: 0px;
  z-index: 10;
  transition: 0.2s ease-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  max-height: 550px;
  overflow-y: hidden;
}
@media screen and (max-width: 600px) {
  .f-drop {
    min-width: 320px;
    left: 0;
  }
}

/* tooltip */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* transform: translateX(-50%); */
  opacity: 0;
  transition: opacity 0.2s;
  /* right: -10%; */
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* tooltip end */

.text-trigger-box {
  max-height: 250px;
  overflow-y: auto;
}

.bvjk {
  position: absolute;
  top: -4px;
  left: -4px;
}
.switch-rect .react-switch-bg svg {
  display: none !important;
}
/* 
.switch-rect { 


} */
.react-switch {
  margin-left: 354px;
}

.react-loader {
  margin-left: 380px;
}

#myTab-auto .nav-link.active {
  background: #065697 !important;
  color: #fff;
}

#myTab-auto .nav-link {
  color: #333;
  border: solid 1px #eee;
  background: #fafafa;
}

.soon-btn {
  background: #ffc1074a;
}

.custom-select {
  min-height: 40px;
}

legend {
  font-size: 14px;
}

.fgoo {
  height: auto !important;
  min-height: 55px;
}

.txt-gy {
  font-weight: 500;
  font-size: 12px;
  color: #666;
}
.fg-cs {
  font-size: 14px;
}

.df-options:hover {
  background: #eee;
}
.search-fgy {
  font-size: 12px;
  height: 40px !important;
}

.filt-del {
  margin-top: -50px !important;
  margin-right: -5px;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: goldenrod;
}
.off {
  color: #ccc;
}

textarea {
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px !important;
  border: 1.5px solid #8692a6 !important;
  min-height: 150px !important;
}

.disable-default-field-div {
  pointer-events: none;
}

.disable-default-field {
  background-color: #f0f0f0;
}

.pint:hover {
  transform: scale(1.02);
  color: #065697;
  text-decoration: underline;
}

.fm-list-container-thead {
  border-radius: 5px;
  border: 1px solid #ccd0d9;
  background: #e6e9ee;
}
.fm-list-container-thead .border-end {
  border-color: #ccd0d9 !important;
}

.switch-status {
  text-align: end;
  margin: 0px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px !important;
}

.builder-stop {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 10;
}

.pre-field,
.new-field,
.build-contianer {
  overflow-y: scroll !important;
  height: 100vh;
  padding-bottom: 100px !important;
}

.pre-field,
.new-field {
  min-width: 300px;
  max-width: 450px;
}

.filt-del-fub {
  margin-top: -60px !important;
  margin-right: -5px;
}

.filt-del-tag {
  margin-top: -60px !important;
  margin-right: -17px;
}
.search-bn {
  height: 40px !important;
}

.add-bn {
  height: 40px;
  width: 40px !important;
  background: #065697;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fi-tab .nav-link.active {
  border-bottom: 3px solid #065697 !important;
  border: none;
}

.fi-tab .nav-link {
  border: none;
  padding-bottom: 10px;
  border-bottom: 3px solid;
  border-color: transparent;
  font-size: 12px !important;
}

.fi-tab .nav-link:hover,
.fi-tab .nav-link:focus {
  border: none;
}

.fm-stack {
  min-width: 100vw;
}

.new-field {
  position: absolute;
  /* transform: translateX(-100%); */
  margin-left: -700px;
  transition: all 0.3s ease-in;
}

.new-field.show {
  margin-left: 0px;
  /* transform: translateX(0%); */
  transition: all 0.15s ease-out !important;
}

.tr-ease {
  transition: all 0.3s;
}

.star-rating {
  margin-left: -3px;
}

.form-floating label,
.form-floatding label {
  max-width: 90%;
}

.form-floating label span span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-floatding label span span {
  white-space: wrap;
  word-break: break-all;
}

.limit-alert {
  background-color: #fae0a4;
  border: 1px solid #edb32e;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}

.limit-icon {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #edb32e;
  border-radius: 100%;
  background: #fff;
  color: #333;
  font-size: 18px;
}

.btn:disabled {
  border: none !important;
}

.fm-crd {
  display: flex;
  padding: 16px 32px;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  background: #fff;
  font-size: 14px;
}

.add-bn:disabled {
  opacity: 0.7;
  box-shadow: none !important;
}

.disabled-test {
  cursor: not-allowed !important;
  background-color: transparent !important;
  background: transparent !important;
}

.disabled-test:active {
  outline: none !important;
  border-color: none !important;
}

.oppstyle {
  text-decoration: none;
  color: inherit;
}

.oppstyle:hover {
  text-decoration: underline;
  color: inherit;
}

.page-item .page-link {
  height: 40px !important;
  width: 45px !important;
  text-align: center;
  color: #444;
}

.page-item.disabled .page-link {
  color: #8692a6 !important;
}

.page-item.active .page-link {
  background: var(--primary) !important;
  color: #fafafa !important;
  border: none;
  font-weight: 400;
}

/* Analytics loading icon */
.position-relative {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 0.375rem;
}

.basic-multi-select {
  width: 100%;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.is-sticky {
  position: fixed;
  background: #f7f7f8;
  top: 64px;
  left: 0;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  margin: auto;
  animation: slideDown 0.35s ease-out;
  z-index: 10;
  padding: 16px 32px;
}

.fm-field-accordion .accordion-button:not(.collapsed) {
  background: #fff;
  color: #333;
}

.fm-field-accordion .accordion-button {
  font-size: 14px;
}

.fm-field-accordion .accordion-body {
  padding: 24px 8px !important;
}

.pre-dispo {
  background: #f2f1fe;
  padding: 8px !important;
  /* margin:0px 16px; */
  /* margin-bottom:-100px; */
}

.dispo-text {
}

.pip-color{
    background: #F1F1F1;
    font-weight:12px;
    font-weight: 500;
}

/* Workflow History CSS */
.event-data-box {
    max-height: 100%;
    overflow-y: auto;
    height: auto;
}

.subkey-value {
    color: #555;
  }
  
  strong {
    font-weight: bold;
  }
  
  .mb-2 {
    margin-bottom: 8px;
  }

.pagination .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
  margin: 0 2px;
  font-size: 16px;
}
/* Workflow History CSS End */

.pip-color {
  background: #f1f1f1;
  font-weight: 12px;
  font-weight: 500;
}

.rdw-editor-toolbar {
  border: none;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 8px 6px 8px;
}

.rdw-editor-wrapper {
  border: 1px solid #8692a6;
  border-radius: 4px;
}

.rdw-editor-main {
  padding: 0px 16px 16px 16px;
}

.rdw-option-active {
  box-shadow: none;
  background: #065697;
  /* background:#F0F7FF; */
}
.rdw-option-wrapper {
  border: none;
  transition: ease-out 0.15s;
  margin: 0 6px;
  height: 30px;
  min-width: 30px;
}
.rdw-option-wrapper:hover {
  box-shadow: none;
  transform: scale(0.97);
  background: #0656971d;
  transition: ease-out 0.15s;
}
.rdw-option-active:hover {
  background: #065697 !important;
}

.rdw-option-disabled {
  display: none;
}

.rdw-link-decorator-wrapper {
  color: #065697;
  text-decoration: underline;
}
.rdw-link-decorator-icon {
  display: none;
}

.rdw-link-modal {
  height: auto !important;
  padding: 24px;
  min-width: 280px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.rdw-link-modal-buttonsection {
  display: flex;
  width: 100%;
  justify-content: start;
  padding: 16px 0px 4px 0px;
  gap: 16px;
}

.rdw-link-modal-btn {
  margin: 0px;
  padding: 6px 32px;
  width: auto;
  height: auto;
  border-radius: 4px !important;
}
.rdw-link-modal-btn:nth-child(1) {
  background: #065697 !important;
  color: #fff;
  border-radius: 4px !important;
}
.rdw-link-modal-btn:nth-child(2) {
  border: 1px solid #065697;
  color: #065697;
}
.rdw-link-modal-input {
  height: 40px;
  padding: 0px 12px;
  border-color: #8692a6 !important;
}

.rdw-link-modal-btn:active,
.rdw-link-modal-btn:hover {
  box-shadow: none !important;
}

.rdw-option-active img {
  filter: invert(100%) !important;
}
