

:root {
    --black: #000;
    --text-color:#373737;
    --body-color:#f7f7f8;
    --off-white:#f7f7f7;
    --table-color:#fff;
    --border-color:#eaeaea;
    --table-head:#065697;
    --tb-hd-border:#065697;
    --ld-stand-1:#fff;
}


[data-theme="light"] {
    --black: #000;
    --text-color:#373737;
    --body-color:#f7f7f8;
    --off-white:#f7f7f7;
    --table-color:#fff;
    --border-color:#DEE2E6;
    --table-head:#065697;
    --tb-hd-border:#065697;
    --ld-stand-1:#fff;
}

[data-theme="dark"] {
    --black: #fff;
    --text-color:#EAEAEA;
    --body-color:#101222;
    --off-white:#101222;
    --table-color:#161828;
    --border-color: #262837;
    --table-head:#161828;
    --tb-hd-border:#262837;
    --ld-stand-1:linear-gradient(180deg, #006BC1 0%, #0C22D3 93%);
}

body{
    background-color: var(--body-color) !important;
    color: var(--text-color) !important;
}


p{
    color: var(--text-color);
}


.ld-header{
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}

.ld-header h3{
    color: var(--text-color);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ld-nav-right{
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}


.ld-stand-cont{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 32px;
}


.ld-stand h5{
    color:var(--black);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
}

.ld-stand p{
    color: #909BBC;
    /* color:rgb(119, 255, 119); */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
}

.ld-stand:nth-of-type(1) {
    background:var(--ld-stand-1);
}

.ld-stand{
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    background: var(--table-color);
    box-shadow: 0px 5px 17px 0px rgba(1, 5, 17, 0.05);
}

.ld-th-title{
    display: flex;
    padding: 18px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    background:var(--table-head);
    color:#fff;
    font-style: normal;
    line-height: normal;
    border: 1px solid var(--tb-hd-border);
}

.ld-th{
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.ld-tr{
    background-color:var(--table-color);
    border-bottom: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}

.ld-tr:nth-of-type(even) {
    background-color: var(--off-white);
}

.ld-td-1{
    display: flex;
    /* width: 100px; */
    padding: 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--text-color);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ld-td-2{
    display: flex;
    padding: 18px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    color: var(--text-color);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ld-td-3{
    display: flex;
    /* width: 200px; */
    padding: 18px;
    align-items: center;
    gap: 10px;
    color: var(--text-color);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}